import React, { useState } from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.css'; 
import { Navbar, Nav, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'react-bootstrap';

const StyledNavbar = styled(Navbar)`
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const BrandLink = styled(Navbar.Brand)`
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
`;

const Emphasis = styled.span`
  color: green;
`;

const NavLink = styled(Nav.Link)`
  color: #333;
  font-size: 1.5rem;
  margin-right: 10px;
  &:hover {
    color: #007bff;
  }
`;

const NavWrapper = styled(Nav)`

  width: 100%; /* Ensure Nav expands to full width */
  padding-top: 20px;
  display: flex;
  justify-content: space-between; /* Align items evenly across the Navbar */
`;

const CustomMenu = styled.div`
  position: absolute;
  background-color: white;
  left: 210px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  z-index:10;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  
  width: 200px;
`


const Header = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <StyledNavbar expand="lg">
      <NavWrapper>
        <Nav>
          <BrandLink style={{paddingTop:"0px"}} href="/">veri<Emphasis>green</Emphasis></BrandLink>
          <NavLink href="/">Home</NavLink>
          <NavLink href="/#/index">Search</NavLink>
          <NavLink onClick={handleOpen}>Solutions</NavLink>
            {open ? (
              <CustomMenu>
              <ul className="menu">
                <li className="menu-item" style={{listStyleType:'none', marginBottom:'10px', marginTop:'10px'}}>
                <NavLink href="/#/consumer" onClick={handleOpen}>Ecocert Check Extension</NavLink>
                </li>
                <li className="menu-item" style={{listStyleType:'none', marginBottom:'15px'}}>
                <NavLink href="/#/sustainability-intelligence" onClick={handleOpen}>Sustainablility Cert Intelligence</NavLink>
                </li>
                <li className="menu-item" style={{listStyleType:'none', marginBottom:'15px'}}>
                <NavLink href="/#/business" onClick={handleOpen}>Sustainablility Cert API</NavLink>
                </li>
              </ul>
              </CustomMenu>
          ) : null}
          <NavLink href="/#/blog">Blog</NavLink>
        </Nav>
        <Nav>
          <NavLink href="mailto:info@verigreen.net">Contact Us</NavLink>
        </Nav>
      </NavWrapper>
    </StyledNavbar>
  );
};

export default Header;
