import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter, useLocation } from 'react-router-dom';

import ReactGA from "react-ga4";
import { GA_MEASUREMENT_ID } from './store/ga_id';

import 'bootstrap/dist/css/bootstrap.min.css';


const ScrollWrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

ReactGA.initialize(GA_MEASUREMENT_ID);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <ScrollWrapper>
        <App />
      </ScrollWrapper>
    </HashRouter>
  </React.StrictMode>
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
reportWebVitals(SendAnalytics);
