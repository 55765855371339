import React from "react";
import Router from "./components/router";
import Header from "./components/header";



function App() {
  return (
    <>
        <Header />
        <Router>
        </Router>
    </>
  );
}

export default App;
