import React, { useEffect, lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

const HomePage = lazy(() => import("../pages/home"))
const BlogPage = lazy(() => import("../pages/blog"))
const ArticlePage = lazy(() => import("../pages/articlePage"))
const ConsumerPage = lazy(() => import("../pages/consumer"))
const ConsumerRegister = lazy(() => import("../pages/consumer/register"))
const UpgradeFlow = lazy(() => import('../pages/consumer/upgrade'))
const BusinessPage = lazy(() => import("../pages/business"))
const IndexPage = lazy(() => import("../pages/index"))
const IntelligencePage = lazy(() => import("../pages/sustainability-intelligence"))

const DisclaimersPage = lazy(() => import("../pages/web_disclaimer"))
const ConsumerTerms = lazy(() => import("../pages/terms"))
const ConsumerPrivacy = lazy(() => import("../pages/privacy"))

const Router = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    return (
        <Routes>
            <Route
                path="/"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <HomePage />
                </Suspense>}
            />
            <Route
                path="/consumer"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <ConsumerPage />
                </Suspense>}
            />
             <Route
                path="/consumer/register"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <ConsumerRegister />
                </Suspense>}
            />
             <Route
                path="/consumer/upgrade"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <UpgradeFlow />
                </Suspense>}
            />
            <Route
                path="/consumer/terms-of-service"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <ConsumerTerms />
                </Suspense>}
            />
            <Route
                path="/consumer/privacy-policy"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <ConsumerPrivacy />
                </Suspense>}
            />
            <Route
                path="/business"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <BusinessPage />
                </Suspense>}
            />
            <Route
                path="/blog"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <BlogPage />
                </Suspense>}
            />
            <Route
                path="/article/:id/:url"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <ArticlePage />
                </Suspense>}
            />
            <Route
                path="/index"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <IndexPage />
                </Suspense>}
            />
            <Route
                path="/sustainability-intelligence"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <IntelligencePage />
                </Suspense>}
            />
            <Route
                path="/legal-disclaimers"
                element={<Suspense fallback={<LoadingIndicator />}> {/* Wrap with Suspense */}
                    <DisclaimersPage />
                </Suspense>}
            />
        </Routes>
    )
}

export default Router;

const LoadingIndicator = () => {
    return <div>Loading...</div>;
};